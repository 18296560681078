.notification {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(50px);

  &__window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    width: 288px;
    padding: 30px 35px 35px;

    border-radius: 20px;
    background-color: white;
    box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 0.50);

    @media (min-width: 425px) {
      width: 350px;
    }
  }

  &__illustration {
    align-self: center;

    margin-bottom: 30px;
  }

  &__heading {
    align-self: center;

    margin: 0 0 30px;

    text-align: center;
  }

  &__text {
    margin: 0 0 30px;

    color: #404040;

    text-align: center;
  }

  &__button {
    align-self: center;

    max-width: 90%;
    margin: 0;
  }
}