.camera {
  position: relative;

  width: 100vw;
  height: 100vh;

  overflow: hidden;

  background-color: black;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;

    width: 70%;

    content: url("qr-code-scan-frame.svg");

    transform: translate(-50%, -50%);
    filter: opacity(0.5);
  }

  &__video {
    display: block;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  &__controls {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}