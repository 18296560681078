.icon-button{padding:0;border:none;background-color:transparent;cursor:pointer;transition:opacity 0.25s}.icon-button:active{opacity:0.6}.icon-button:focus{outline:none}

.camera{position:relative;width:100vw;height:100vh;overflow:hidden;background-color:black}.camera::before{position:absolute;top:50%;left:50%;display:block;width:70%;content:url(/static/media/qr-code-scan-frame.f4a4715a.svg);transform:translate(-50%, -50%);-webkit-filter:opacity(0.5);filter:opacity(0.5)}.camera__video{display:block;width:100%;height:100%;object-fit:cover}.camera__controls{position:absolute;z-index:1;top:10px;right:10px;bottom:10px;left:10px;display:flex;align-items:flex-start;justify-content:flex-end;flex-wrap:wrap}

.button{height:40px;padding:0 25px;color:white;border:none;border-radius:8px;background-color:#3771e0;cursor:pointer;transition:background-color 0.25s}.button:hover{background-color:#3e83ff}.button:active{background-color:#3367cd}.button:focus{outline:none;box-shadow:0 0 0 2px rgba(115,145,200,0.5)}

.notification{position:fixed;z-index:2;top:0;right:0;bottom:0;left:0;background-color:rgba(0,0,0,0.3);-webkit-backdrop-filter:blur(50px);backdrop-filter:blur(50px)}.notification__window{position:fixed;top:50%;left:50%;transform:translate(-50%, -50%);display:flex;flex-direction:column;box-sizing:border-box;width:288px;padding:30px 35px 35px;border-radius:20px;background-color:white;box-shadow:10px 10px 25px 0 rgba(0,0,0,0.5)}@media (min-width: 425px){.notification__window{width:350px}}.notification__illustration{align-self:center;margin-bottom:30px}.notification__heading{align-self:center;margin:0 0 30px;text-align:center}.notification__text{margin:0 0 30px;color:#404040;text-align:center}.notification__button{align-self:center;max-width:90%;margin:0}

.video__player{position:absolute;width:100%;height:100%}

.app{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100vw;height:100vh;background-color:black;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;touch-action:manipulation}.app__logo{display:block;margin-bottom:20px}

body{margin:0}

