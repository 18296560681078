.button {
  height: 40px;
  padding: 0 25px;

  color: white;
  border: none;
  border-radius: 8px;
  background-color: #3771e0;

  cursor: pointer;
  transition: background-color 0.25s;

  &:hover {
    background-color: #3e83ff;
  }

  &:active {
    background-color: #3367cd;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(115, 145, 200, 0.5);
  }
}