.icon-button {
  padding: 0;

  border: none;
  background-color: transparent;

  cursor: pointer;
  transition: opacity 0.25s;

  &:active {
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
}